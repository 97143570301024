import React from "react";
import Navbar from "../sections/Navbar/Navbar";
// @ts-ignore
import Seo from "../utils/seo";
import IndividualCoachingHero from "../sections/IndividualCoachingHero";
import Benefits from "../sections/Benefits";
import Example from "../sections/Example";
import ProgramHelp from "../sections/ProgramHelp";
import Faq from "../sections/FaqFr";
import Footer from "../sections/Footer";
import InternalCoachingHero from "../sections/InternalCoachingHeroFr.";
import CaseStudies from "../sections/CaseStudiesFr";
// @ts-ignore
import Layout from "../layouts";
import Leaders from "../sections/LeadersFr";
const InternalCoaching = () => {
  return (
    <>
      <Seo
        title="Coaching interne: Développez vos talents en entreprise" description="Optimisez l'engagement de vos collaborateurs grâce à un coaching interne innovant et orienté vers la réussite collective."
        // image={seoImg}
      />
      <Layout>
      <InternalCoachingHero />
      <CaseStudies />
      <Leaders />
      <Faq />
      </Layout>
    </>
  );
};

export default InternalCoaching;
